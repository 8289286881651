module.exports = {
  VERIFY_USER:'VERIFY_USER',
  LOGIN: 'LOGIN',
  USER_JOINED: 'USER_JOINED',
  ADD_USER:'ADD_USER',
  NEW_MESSAGE:'NEW_MESSAGE',
  TYPING:'TYPING',
  STOP_TYPING:'STOP_TYPING',
  USER_LEFT:'USER_LEFT'
}
