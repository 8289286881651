import React from 'react';
import Layout from './components/Layout'

function App() {
  return (
    <Layout />
  );
}

export default App;
